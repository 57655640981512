export default {
  data() {
    return {};
  },
  methods: {
    showLogin() {
      this.$store.commit("modals/showModal", "login");
    },
    showRegister() {
      this.$store.commit("modals/showModal", "register");
    },
    showRestore() {
      this.$store.commit("modals/showModal", "restore");
    },
    doConfirm() {
      this.$buefy.snackbar.open({
          message: 'Please confirm your email address to be able to use all our features',
          type: 'is-warning',
          position: 'is-top',
          actionText: 'CONFIRM',
          indefinite: true,
          onAction: () => {
            console.log("open confirm!");
            this.$store.commit("modals/showModal", "confirm");   
          }
      })
    },
    sanitizeString(str){
      str = str.replace(/['"`]/gim,""); //eslint-disable-line
      return str.trim();
    }
  }
};
