export default {
  methods: {
    doConfirm() {
      this.$buefy.snackbar.open({
          message: 'Please confirm your email address to be able to use all our features',
          type: 'is-warning',
          position: 'is-top',
          actionText: 'CONFIRM',
          indefinite: true,
          onAction: () => {
            console.log("open confirm!");
            this.$store.commit("SHOW_MODAL", {
              modal: "confirm",
              state: true
            });   
          }
      })
    }
  }
};
